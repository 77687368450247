import Bio from "../components/bio"
import Layout from "../components/layout"
import React from "react"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"
import { rhythm, scale } from "../utils/typography"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={post.frontmatter.image}
      />
      <article>
        <header>
          <h1
            style={{
              marginTop: rhythm(1),
              marginBottom: 0,
            }}
          >
            {post.frontmatter.title}
          </h1>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >
            {post.frontmatter.date}
          </p>
        </header>
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <footer>
          <Bio />
        </footer>
      </article>
      <hr
        style={{
          marginBottom: rhythm(1),
        }}
      />
      <nav>
        <ul>
          <li>
            {previous && (
              <Link
                to={`/blog${previous.fields.slug}`}
                rel="prev"
                title={previous.frontmatter.title}
              >
                <i className="fad fa-arrow-circle-left"></i>
                <span>{previous.frontmatter.title}</span>
              </Link>
            )}
          </li>
          <li>
            <Link to={"/blog"} rel="index">
              All Posts
            </Link>
          </li>
          <li>
            {next && (
              <Link
                to={`/blog${next.fields.slug}`}
                rel="next"
                title={next.frontmatter.title}
              >
                <i className="fad fa-arrow-circle-right"></i>
                <span>{next.frontmatter.title}</span>
              </Link>
            )}
          </li>
        </ul>
      </nav>
      <style jsx>{`
        nav ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          list-style: none;
          font-size: smaller;
          padding: 0;
        }

        nav ul li {
          width calc(1 / 3 * 100%);
          position: relative;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        nav ul li :global(a i) {
          position: absolute;
          top: 5px;
        }

        nav ul li:first-child {
          padding-left: 20px;
        }

        nav ul li:first-child :global(a i) {
          left: 0;
        }

        nav ul li:nth-child(2) {
          text-align: center;
        }

        nav ul li:last-child {
          text-align: right;
          padding-right: 20px;
        }

        nav ul li:last-child :global(a i) {
          right: 0;
        }

      `}</style>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        image {
          childImageSharp {
            fixed(width: 600) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    }
  }
`
